
export default {
  props: {
    disabled: Boolean,
    max: {
      type: Number,
      default: Infinity,
    },
    min: {
      type: Number,
      default: -Infinity,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    step: {
      type: Number,
      default: 1,
    },
    precision: {
      type: Number,
      default: 0,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value,
      decrementDisabled: false,
      incrementDisabled: false,
      inputDisabled: false,
    };
  },

  watch: {
    value(val) {
      this.currentValue = Number(val);
    },
  },

  mounted() {
    if (Number(this.value) === this.min) {
      this.decrementDisabled = true;
    }
  },

  methods: {
    increment() {
      if (this.disabled || this.incrementDisabled) {
        return;
      }

      const newVal = this.currentValue + this.step;
      this.decrementDisabled = false;

      this.updateValue(newVal);
    },
    decrement() {
      if (this.disabled || this.decrementDisabled) {
        return;
      }

      const newVal = this.currentValue + -1 * this.step;
      this.incrementDisabled = false;

      this.updateValue(newVal);
    },
    updateValue(newVal) {
      if (typeof this.value !== "number") {
        return;
      }
      this.decrementDisabled = false;
      this.incrementDisabled = false;
      if (newVal <= this.min) {
        newVal = this.min;
        this.decrementDisabled = true;
      }
      if (newVal >= this.max) {
        newVal = this.max;
        this.incrementDisabled = true;
      }
      this.currentValue = Number(newVal.toFixed(this.precision));
      this.$emit("input", this.currentValue);
    },
  },
};
